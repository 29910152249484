<template>
  <div class="container c-515 mt-2">
    <router-link
      :to="{ name: 'list-select' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center">
          <i class="fas fa-long-arrow-left" />
        </div>&nbsp;&nbsp;
        <h5 class="text-center font-weight-bolder m-0">
          ถอนเครดิต
        </h5>
      </div>
    </router-link>
    <div class="section-wrap pd-v-20">
      <div class="box-alert warning d-flex mb-10 align-items-center"><i class="fas fa-exclamation-triangle" />
        <div class="text">
          คุณสามารถใช้สิทธิการถอนได้ 5 ครั้งต่อวันคุณใช้สิทธิถอนไปแล้ว  {{ WithdrawCount }}  ครั้ง
        </div>
      </div>
      <div class="box-radio-list">
        <div class="radio-items checked">
          <label class="input-container radio-area d-flex align-items-center">
            <div class="inner-radio">
              <input
                type="radio"
                name="radio"
                checked=""
                class="radio"
              ><span class="radiomark" />
            </div>
            <img
              :src="`/bankIcon/${type === 'truewallet' ? 'wallet.png' :UserRes.path_photo}`"
              style="width:32px;border-radius:.25rem;padding:7px;margin-right:10px"
              :style="`background-color: ${type === 'truewallet' ? '#ffbb81' : UserRes.bg};`"
            > <strong class="text-radio" />
            <div class="d-flex flex-grow-1 justify-content-between align-items-end m-d-block">
              <div class="box-left"><strong class="text-radio">{{ type === 'truewallet' ? 'truewallet' : UserRes.bankname }}</strong><br><span class="font-sub-title color-sub">{{ type === 'truewallet' ? UserRes.trueaccno : UserRes.acc_no }}</span></div>
              <div class="box-right ml-auto"><span class="font-sub-title color-charcoal">{{ UserRes.fname }} {{ UserRes.sname }}</span></div>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="section-wrap pd-v-5">
      <div class="section-title-l m-no-padding"><strong class="font-normal">ยอดคงเหลือ</strong>
        <div class="clearfix" />
      </div>
      <div class="mb-10">
        <strong class="font-xxl color-green amount-random-decimal">{{ Commas(this.$store.state.appConfig.UserData.MainWallet) }}</strong> <strong class="font-heading color-green">บาท</strong>
      </div>
    </div>

    <div class="section-wrap">
      <div class="section-title-l m-no-padding"><strong class="font-normal">ระบุจำนวนเงินที่ต้องการถอน</strong>
        <div class="clearfix" />
      </div>
      <div class="form-group-10 form-group has-feedback">
        <div class="input-2-box">
          <input
            v-model="amount"
            :disabled="WithdrawCount >= 5"
            type="tel"
            autocomplete="off"
            placeholder="ระบุจำนวนเงินที่ต้องการถอน"
            class="form-control"
            data-fv-field="amount"
          ><i
            class="form-control-feedback"
            data-fv-icon-for="amount"
            style="display: none;"
          />
          <div class="text-control">
            THB
          </div>
        </div>
        <small
          class="help-block"
          data-fv-validator="notEmpty"
          data-fv-for="amount"
          data-fv-result="NOT_VALIDATED"
          style="display: none;"
        >โปรดระบุข้อมูลในส่วนนี้</small><small
          class="help-block"
          data-fv-validator="integer"
          data-fv-for="amount"
          data-fv-result="NOT_VALIDATED"
          style="display: none;"
        >กรุณาระบุยอดเป็นจำนวนเต็มเท่านั้น ห้ามระบุทศนิยม</small><small
          class="help-block"
          data-fv-validator="greaterThan"
          data-fv-for="amount"
          data-fv-result="NOT_VALIDATED"
          style="display: none;"
        >กรุณาระบุค่ามากกว่าหรือเท่ากับ %s</small></div>
      <div class="form-group-10 form-group ">
        <input
          id="remark"
          :disabled="WithdrawCount >= 5"
          name="remark"
          class="form-control"
          rows="3"
          placeholder="หมายเหตุ"
        >
      </div>
      <div class="box-form-button">
        <button
          :disabled="WithdrawCount >= 5"
          class="btn btn-primary btn-100 mb-10"
          @click="SummitBet()"
        >
          แจ้งถอน
        </button>
        <a
          href="/"
          class="btn btn-transparent btn-100 mt-1"
        >ยกเลิกรายการ</a>
      </div>
    </div>
  </div>
</template>

<script>
// import liff from '@line/liff'

export default {
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      message: '',
      type: 'bank',
      WithdrawCount: 0,
      UserRes: {
        username: '',
        tel: '',
        fname: '',
        sname: '',
        bank: '',
        acc_no: '',
        trueaccno: '',
        created_at: '',
      },
      amount: null,
      // IntervalWallet: null,
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.IntervalWallet)
  // },
  mounted() {
    this.ShowUser()
    this.ShowWallet()
    this.IntervalWallet = setInterval(() => {
      this.ShowWallet()
    }, 5000)
  },
  methods: {
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://lottothaiapi.linelotto.vip/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
          this.WithdrawCount = response.WithdrawCount
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
          this.WithdrawCount = response.WithdrawCount
        }
      } catch (err) {
        console.log(err)
      }
    },
    async SummitBet() {
      if (this.amount && this.amount > 0) {
        const Obj = {
          amount: this.amount,
        }
        try {
          // const { data: response } = await this.$http.post(
          //   'https://lottothaiapi.linelotto.vip/api/withdraw/store',
          //   Obj,
          // )
          const { data: response } = await this.$http.post(
            'https://lottothaiapi.linelotto.vip/api/withdraw/store',
            Obj,
          )
          if (response && response.success === true) {
            this.ShowUser()
            const { data: res } = await this.$http.get('https://lottothaiapi.linelotto.vip/api/wallet/show')
            if (res && res.success === true) {
              const Main = res.WalletData.MainWallet
              this.$store.state.appConfig.UserData.MainWallet = Main.balance
              this.SwalMes(
                'success',
                'แจ้งถอนสำเร็จ',
                'ระบบกำลังทำรายการกรุณารอสักครู่',
              )
              // ถอนทั้งหมด ${this.Commas(
              //     response.Data.amount,
              //   )} บาท ยอดคงเหลือ ${this.Commas(response.Data.balance)} บาท
              // liff.closeWindow()
            } else {
              this.$store.state.appConfig.UserData.MainWallet = 0
            }
          }
        } catch (err) {
          this.SwalMes('error', 'ถอนไม่สำเร็จ', err.response.data.Mes)
        }
      } else {
        this.SwalMes('error', 'ถอนไม่สำเร็จ', 'กรุราระบุจำนวนเงินที่ต้องการถอน')
      }
    },
    async ShowUser() {
      try {
        const { data: response } = await this.$http.get(
          'https://lottothaiapi.linelotto.vip/api/user/show',
        )
        if (response && response.success === true) {
          this.type = response.type
          this.UserRes = response.UserData
        }
      } catch (err) {
        console.log(err)
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    SwalMes(Type, Title, Text) {
      this.$swal({
        icon: Type,
        title: Title,
        text: Text,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.input-2-box .form-control {
    border-radius: 0;
    border: none;
    flex-grow: 1;
}
.form-control {
    padding: 10px;
    line-height: 22px;
    border-color: #E6E6E6;
    color: #000;
    font-size: 15px;
    height: 45px;
    font-weight: 700;
}
.form-control {
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}

</style>
